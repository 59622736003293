import './index.css'
import 'react-notifications-component/dist/theme.css'

import App from './App'
import React from 'react'
import ReactDOM from 'react-dom'
import ReactNotification from 'react-notifications-component'

function renderApp() {
  ReactDOM.render(
  <div>
    <ReactNotification
      isMobile={true}
      />
    <App/>
  </div>, document.getElementById('root'))
}

renderApp()
