import {asType} from './util'

export type RgbColor = {
  red: number
  green: number
  blue: number
}

export type RgbaColor = RgbColor & {
  alpha: number
}

export function toRgbString(rgb: RgbColor) {
  return `rgb(${rgb.red}, ${rgb.green}, ${rgb.blue})`
}

export function toRgbaString(rgba: RgbaColor) {
  return `rgba(${rgba.red}, ${rgba.green}, ${rgba.blue}, ${rgba.alpha})`
}

export const shellBgColors = asType<RgbColor>({red: 0, green: 0, blue: 0})
export const shellBgColor = toRgbString(shellBgColors)

export const millisPerHour = 3600000
