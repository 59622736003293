import {NotificationOptions} from 'react-notifications-component'
import {asType} from './util'
import {millisPerHour} from "./consts"

const defaultExitDuration = 400
const defaultExitDelay = 0
export const defaultNotificationOptions = asType<NotificationOptions>({
  container: 'top-left',
  insert: 'top',
  type: 'info',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  slidingExit: {
    duration: defaultExitDuration,
    timingFunction: 'ease-out',
    delay: defaultExitDuration,
  },
  touchSlidingExit: {
    swipe: {
      duration: defaultExitDuration,
      timingFunction: 'ease-out',
      delay: defaultExitDelay,
    },
    fade: {
      duration: defaultExitDuration,
      timingFunction: 'ease-out',
      delay: defaultExitDelay
    }
  },
  dismiss: {
    duration: 3000,
    showIcon: true,
  }
})

export const defaultErrorNotificationOptions = asType<NotificationOptions>({
  ...defaultNotificationOptions,
  title: 'Error',
  type: 'danger',
  dismiss: {
    ...defaultNotificationOptions.dismiss,
    duration: 10000,
  }
})

export const defaultSuccessNotificationOptions = asType<NotificationOptions>({
  ...defaultNotificationOptions,
  title: 'Success',
  type: 'success',
  dismiss: {
    ...defaultNotificationOptions.dismiss,
    duration: 600,
  }
})

export function wait(timeout: number, onSetTimeoutId?: (id: number) => any) {
  return new Promise<void>(resolve => {
    const id = setTimeout(resolve, timeout)
    if (onSetTimeoutId) {
      onSetTimeoutId(id)
    }
  })
}

export function defer(onSetTimeoutId?: (id: number) => any) {
  return wait(0, onSetTimeoutId)
}

export function isNullOrUndefined(value?: any) {
  return value === undefined || value === null
}

export function isNullUndefinedOrEmpty(value?: any) {
  return isNullOrUndefined(value) || value === ''
}

export function isBlank(value: string | null | undefined) {
  return !value || !value.trim()
}

export function getHoursSince(since: Date, now: Date) {
    const millis = now.getTime() - since.getTime()
    const hours = millis / millisPerHour
    return hours
}
