import './App.css'

import * as util from './util'

import React, {Component, createRef} from 'react'
import {RecordRTCPromisesHandler, RecordingState, invokeSaveAsDialog} from 'recordrtc'
import {RgbColor, toRgbString, toRgbaString} from './consts'
import {Theme, ThemeProvider, createMuiTheme} from '@material-ui/core/styles'
import withStyles, {StyleRules} from '@material-ui/core/styles/withStyles'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import RecordIcon from '@material-ui/icons/FiberManualRecord'
import StopRecordIcon from '@material-ui/icons/Stop'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MaterialImage from 'material-ui-image'
import ReactPlayer from 'react-player'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import copyToClipboard from 'clipboard-copy'
import Slider from '@material-ui/core/Slider'
import {defaultNotificationOptions, wait} from './global'
import moment from 'moment'
import {store as notificationStore} from 'react-notifications-component'
import * as uuid from 'uuid'

const pageBgColor = util.asType<RgbColor>({red: 21, green: 21, blue: 21})
const pageBgColorString = toRgbString(pageBgColor)

const primaryColor = util.asType<RgbColor>({red: 59, green: 227, blue: 244})

const maxDelay = 2

const PrettoSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider)

function assertDefined<T>(obj: T): asserts obj is NonNullable<T> {
  if (obj === undefined || obj === null) {
    throw new Error('Must not be a nullable value');
  }
}

const theme = createMuiTheme({
  typography: {
    // fontFamily: '"Source Code Pro", monospace',
    fontFamily: 'Monaco, "Bitstream Vera Sans Mono", "Lucida Console", Terminal, monospace',
    fontSize: 12,
  },
  palette: {
    type: 'dark',
    background: {
      default: pageBgColorString,
      paper: pageBgColorString,
    },
    primary: {
      main: toRgbString(primaryColor),
    },
    text: {
      primary: toRgbString(primaryColor),
      secondary: toRgbaString({...primaryColor, alpha: 0.7}),
      disabled: toRgbaString({...primaryColor, alpha: 0.5}),
      hint: toRgbaString({...primaryColor, alpha: 0.5}),
    },
    action: {
      active: toRgbString(primaryColor),
    }
  },
})

const styles = (theme: Theme) => util.asType<StyleRules>({
  demoBox: {
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
  flex: {
    flex: 1
  },
  root: {
    width: '100%',
  },
  sliderDiv: {
    margin: `${theme.spacing(2)}px ${theme.spacing(12)}px ${
      theme.spacing(0)}px ${theme.spacing(2)}`,
  },
})

function formatNumber(value: number | null | undefined, precision: number) {
  if (value === null || value === undefined) {
    return ''
  }

  return value.toFixed(precision)
}

function toDateString(date: Date) {
  return date.toLocaleString('en-GB')
}

function formatDate(value: string) {
  let valueDate = new Date(value)
  return moment(valueDate).calendar()
}

type ReloadableImageProps = {
  aspectRatio: number
  color?: string
  onError?: () => void
  onLoad?: () => void
  retryCount: number
  retryDelay: number
  src: string
}
type ReloadableImageState = {
  reloadCount: number
  actualAspectRatio?: number
}
class ReloadableImage extends Component {
  // @ts-ignore
  props: ReloadableImageProps
  state: ReloadableImageState = {
    reloadCount: 0,
    actualAspectRatio: undefined,
  }
  retryCount = 0
  static defaultProps: Partial<ReloadableImageProps> = {
    retryDelay: 1000
  }
  retryTimeout?: any
  failed?: boolean
  loaded?: boolean
  handleClick = () => {
    if (this.failed) {
      delete this.loaded
      delete this.failed

      this.retryLoad()
    }
  }
  handleLoad = () => {
    // TODO: make sure this doesn't happen if the image src was changed or the image unmounted
    this.loaded = true
    delete this.failed
    delete this.retryCount

    // Correct aspect ratio
    const img = new Image()
    img.onload = () => {
      this.setState({
        actualAspectRatio: img.width / img.height,
      }, () => this.finishLoad())
    }
    img.onerror = () => this.finishLoad()
    img.src = this.getFullSrc()
  }
  finishLoad = () => {
    if (this.props.onLoad) {
      this.props.onLoad()
    }
  }
  retryLoad = () => {
    this.setState((state: ReloadableImageState, _props) => ({
      reloadCount: state.reloadCount + 1
    }))
  }
  handleError = () => {
    if (this.props.retryCount < 0 || this.retryCount < this.props.retryCount) {
      ++this.retryCount
      this.retryTimeout = setTimeout(this.retryLoad, this.props.retryDelay)
    } else {
      this.failed = true
      delete this.loaded
      if (this.props.onError) {
        this.props.onError()
      }
    }
  }
  getFullSrc = () => {
    const {src} = this.props
    const {reloadCount} = this.state

    const noCachePart = util.strIf(() => `&reloadCount=${reloadCount}`, reloadCount > 0)
    return `${src}${noCachePart}`
  }
  render() {
    const {color, src, aspectRatio} = this.props
    const {actualAspectRatio} = this.state

    return (
      <MaterialImage
        key={src}
        color={color}
        src={this.getFullSrc()}
        aspectRatio={actualAspectRatio !== undefined ? actualAspectRatio : aspectRatio}
        onClick={this.handleClick}
        onLoad={this.handleLoad}
        onError={this.handleError}
      />
    )
  }
}

type AppProps = {
  classes: any
}
type AppState = {
  isLoading: boolean
  recordingState?: RecordingState
  guideVideoUrl?: string | MediaStream
  guideVideoIsPlaying: boolean
  guideVideoIsLooping: boolean
  guideVideoIsMuted: boolean
  recordingVideoUrl?: string | MediaStream
  recordingVideoIsPlaying: boolean
  recordingVideoIsLooping: boolean
  recordingVideoIsMuted: boolean
  recordingVideoVolume: number
  recordingVideoDelay: number
  sessionId: string
  recordingNumber: number
  layoutStyle: 'compact' | 'fullSize'
}
class Index extends Component<AppProps, AppState> {
  state: AppState = {
    isLoading: false,
    recordingState: 'inactive',
    guideVideoIsPlaying: false,
    guideVideoIsLooping: false,
    guideVideoIsMuted: true,
    recordingVideoIsPlaying: false,
    recordingVideoIsLooping: false,
    recordingVideoIsMuted: true,
    recordingVideoVolume: 1,
    recordingVideoDelay: 0,
    sessionId: uuid.v4(),
    recordingNumber: 0,
    layoutStyle: 'compact',
  }
  stream?: MediaStream
  recorder?: RecordRTCPromisesHandler
  guidePlayerRef = createRef<ReactPlayer>()
  recordingPlayerRef= createRef<ReactPlayer>()
  recordedGuide = false
  static defaultProps: Partial<AppProps> = {
  }
  async componentDidUpdate(_prevProps: AppProps) {
    // do nothing
  }
  async componentDidMount() {
    // do nothing
    await this.initRecorder()
  }
  seek(seconds: number) {
    if (this.guidePlayerRef.current) {
      this.guidePlayerRef.current.seekTo(seconds, 'seconds')
      this.syncRecordingVideoWithGuide()
    }
  }
  async initRecorder() {
    if (!this.recorder) {
      this.stream = await navigator.mediaDevices.getUserMedia({video: true, audio: true})
      this.recorder = new RecordRTCPromisesHandler(this.stream, {
        type: 'video',
        numberOfAudioChannels: 1,
        disableLogs: true,
        //desiredSampRate: 44100,
        //audioBitsPerSecond: 320000,
      })
      this.recorder.recordRTC.onStateChanged.call = (_self, state) => this.setState({recordingState: state})
      console.log(this.recorder)
    } else {
      await this.recorder.reset()
    }
    if (this.recordedGuide) {
      this.seek(0)
      this.setState({
        recordingVideoUrl: this.stream,
        recordingVideoIsPlaying: true,
        recordingVideoIsMuted: true,
        guideVideoIsPlaying: false,
        guideVideoIsMuted: false,
      })
    } else {
      this.setState({
        guideVideoUrl: this.stream,
        guideVideoIsPlaying: true,
        guideVideoIsMuted: true,
      })
    }
  }
  async startRecording() {
    await this.initRecorder()
    /*
    notificationStore.addNotification({
      ...defaultNotificationOptions,
      title: 'Starting recording',
      message: '',
    })
    */
    assertDefined(this.recorder)
    this.recorder.startRecording()
    if (this.recordedGuide) {
      this.setState((prev) => ({
        guideVideoIsPlaying: true,
        recordingVideoIsMuted: true,
        recordingNumber: prev.recordingNumber + 1,
      }))
    }
  }
  async stopRecording() {
    /*
    notificationStore.addNotification({
      ...defaultNotificationOptions,
      title: 'Stopping recording',
      message: '',
    })
    */
    assertDefined(this.recorder)
    if (this.recordedGuide) {
      this.setState({
        guideVideoIsPlaying: false,
      })
    }
    const blobUrl = await this.recorder.stopRecording()
    console.log(`blobUrl: ${blobUrl}`)
    //const blob = await this.recorder.getBlob()
    /*
    notificationStore.addNotification({
      ...defaultNotificationOptions,
      title: 'Saving recording',
      //message: `${commandLabel}`,
      message: '',
    })
    this.recorder.recordRTC.save('my-rtc-recording.webm')
    */
    if (this.recordedGuide) {
      this.setState({
        recordingVideoUrl: blobUrl,
        recordingVideoIsPlaying: false,
        recordingVideoIsMuted: false,
        guideVideoIsPlaying: false,
      })
    } else {
      this.recordedGuide = true
      this.setState({
        guideVideoUrl: blobUrl,
        guideVideoIsPlaying: true,
        guideVideoIsMuted: false,
      })
    }
  }
  syncRecordingVideoWithGuide() {
    if (this.recordingPlayerRef.current) {
      assertDefined(this.guidePlayerRef.current)
      const currentGuideTime = this.guidePlayerRef.current.getCurrentTime()
      this.recordingPlayerRef.current.seekTo(currentGuideTime + this.state.recordingVideoDelay, 'seconds')
    }
  }
  onPlay() {
    this.syncRecordingVideoWithGuide()
    this.setState({
      recordingVideoIsPlaying: true,
      guideVideoIsPlaying: true,
    })
  }
  onPause() {
    this.setState({
      recordingVideoIsPlaying: false,
      guideVideoIsPlaying: false,
    })
    this.syncRecordingVideoWithGuide()
  }
  async onEnded() {
    this.setState({
      guideVideoIsPlaying: false,
    })

    if (this.recorder && this.recorder.recordRTC.getState() === 'recording') {
      await wait(Math.round(maxDelay * 1000))
      this.stopRecording()
    }
  }
  async submitRecording() {
    assertDefined(this.recorder)
    const recordingName = `recording-${this.state.sessionId}-${this.state.recordingNumber}`
    const manifest = {
      delay: this.state.recordingVideoDelay,
      sessionId: this.state.sessionId,
      recordingNumber: this.state.recordingNumber,
    }
    this.recorder.recordRTC.save(`${recordingName}.webm`)
    const manifestBlob = new Blob([JSON.stringify(manifest)], {type: 'text/plain;charset=utf-8'})
    invokeSaveAsDialog(manifestBlob, `${recordingName}.json`)
  }
  onSeek(_seconds: number) {
    this.syncRecordingVideoWithGuide()
  }
  render() {
    const {
      classes,
    } = this.props
    const {
      recordingState,
      guideVideoUrl,
      guideVideoIsPlaying,
      guideVideoIsLooping,
      guideVideoIsMuted,
      recordingVideoUrl,
      recordingVideoIsPlaying,
      recordingVideoIsLooping,
      recordingVideoIsMuted,
      recordingVideoVolume,
      recordingVideoDelay,
      sessionId,
      recordingNumber,
      layoutStyle,
    } = this.state

    const containerSpace = 2
    const videoMaxWidth = 467

    const delayMarkInterval = 0.25
    const delayMarkCount = Math.ceil(maxDelay / delayMarkInterval)

    return (
      <ThemeProvider theme={theme}>
        <div className={classes.root} style={{backgroundColor: pageBgColorString}}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Box margin={containerSpace}>
                <Typography color="textPrimary" variant="h2"><b>Virtual Choir {sessionId}-{recordingNumber}</b></Typography>
              </Box>
            </Grid>
            <Grid item xs={layoutStyle === 'compact' ? 5 : 12} md={5}>
              <Box
                className={classes.demoBox}
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                alignContent="flex-start"
              >
                <Box
                  bgcolor={pageBgColorString}
                  overflow="hidden"
                  margin={containerSpace}
                  width="100%"
                  maxWidth={videoMaxWidth}
                  border="2px solid rgb(40, 40, 40)"
                  borderRadius="1%"
                >
                  <Box>
                    {guideVideoUrl &&
                      <ReactPlayer
                        ref={this.guidePlayerRef}
                        key={guideVideoUrl instanceof MediaStream ? 'media' : guideVideoUrl}
                        url={guideVideoUrl}
                        playing={guideVideoIsPlaying}
                        loop={guideVideoIsLooping}
                        muted={guideVideoIsMuted}
                        controls
                        width="100%" height="100%"
                        onPlay={() => this.onPlay()}
                        onPause={() => this.onPause()}
                        onEnded={() => this.onEnded()}
                        onSeek={seconds => this.onSeek(seconds)}
                      />
                    }
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={layoutStyle === 'compact' ? 2 : 12} md={2}>
              <Box margin={containerSpace}>
                <IconButton
                  onClick={recordingState === 'recording' ? () => this.stopRecording() : () => this.startRecording()}
                >{recordingState === 'recording' ? <StopRecordIcon /> : <RecordIcon htmlColor="red" />}
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={layoutStyle === 'compact' ? 5 : 12} md={5}>
              <Box
                className={classes.demoBox}
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                alignContent="flex-start"
              >
                <Box
                  bgcolor={pageBgColorString}
                  overflow="hidden"
                  margin={containerSpace}
                  width="100%"
                  maxWidth={videoMaxWidth}
                  border="2px solid rgb(40, 40, 40)"
                  borderRadius="1%"
                >
                  <Box>
                    {recordingVideoUrl &&
                      <ReactPlayer
                        ref={this.recordingPlayerRef}
                        key={recordingVideoUrl instanceof MediaStream ? 'media' : recordingVideoUrl}
                        url={recordingVideoUrl}
                        playing={recordingVideoIsPlaying}
                        loop={recordingVideoIsLooping}
                        muted={recordingVideoIsMuted}
                        controls
                        volume={recordingVideoVolume}
                        width="100%" height="100%"
                      />
                    }
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box margin={containerSpace}>
                <div className={classes.sliderDiv}>
                  <Typography color="textPrimary" id="syncDelayLabel">Delay</Typography>
                  <PrettoSlider
                    valueLabelDisplay="auto"
                    value={recordingVideoDelay}
                    aria-labelledby="syncDelayLabel"
                    step={0.001}
                    min={0}
                    max={maxDelay}
                    marks={[...Array(delayMarkCount + 1).keys()].map(index => ({value: index * delayMarkInterval}))}
                    onChange={(_event, value) => {
                      if (Array.isArray(value)) throw new Error()
                      this.setState({recordingVideoDelay: value})
                    }}
                    onChangeCommitted={(_event, value) => {
                      if (Array.isArray(value)) throw new Error()
                      this.setState({recordingVideoDelay: value})
                      this.syncRecordingVideoWithGuide()
                    }}
                  />
                </div>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box margin={containerSpace}>
                <Button
                  disabled={typeof recordingVideoUrl !== 'string'}
                  onClick={() => this.submitRecording()}
                >Save</Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      </ThemeProvider>
    )
  }
}

const App = withStyles(styles)(Index)

export default App
